<template>
  <div class="w100w">
    <div class="pagenamew">
      <span class="couponn">선물 가능한 쿠폰: <span class="pkc">8</span>장</span>
      <span class="coutxt"><span class="blc">쿠폰선물하기</span> 버튼을 클릭하 쿠폰받을 회원을 선택하세요.</span>
    </div>
    <div class="board">
      <ul class="boardw first">
        <li class="">선택</li>
        <li class="">쿠폰명</li>
        <li class="">쿠폰금액</li>
        <li class="">보낸회원아이디</li>
        <li class="">처리상태</li>
        <li class="">발급일</li>
        <li class="">만료일</li>
      </ul>
      <ul class="boardw">
        <li class=""><input type="checkbox" /></li>
        <li class="">테스트쿠폰</li>
        <li class="rdc">10,000</li>
        <li class="">eric</li>
        <li class="rdc">사용가능</li>
        <li class="">2021-06-29</li>
        <li class="">2021-07-29</li>
      </ul>
      <ul class="boardw">
        <li class=""><input type="checkbox" /></li>
        <li class="">테스트쿠폰</li>
        <li class="rdc">10,000</li>
        <li class="">eric</li>
        <li class="rdc">사용가능</li>
        <li class="">2021-06-29</li>
        <li class="">2021-07-29</li>
      </ul>
      <ul class="boardw">
        <li class=""><input type="checkbox" /></li>
        <li class="">테스트쿠폰</li>
        <li class="rdc">10,000</li>
        <li class="">eric</li>
        <li class="rdc">사용가능</li>
        <li class="">2021-06-29</li>
        <li class="">2021-07-29</li>
      </ul>
      <ul class="boardw">
        <li class=""><input type="checkbox" /></li>
        <li class="">테스트쿠폰</li>
        <li class="rdc">10,000</li>
        <li class="">eric</li>
        <li class="rdc">사용가능</li>
        <li class="">2021-06-29</li>
        <li class="">2021-07-29</li>
      </ul>
    </div>

    <div class="boardbtn"><a class="blsbtn right" @click="openCouponGift">쿠폰선물하기</a></div>
    <pagination :className="'pt60'" />

  </div>

  <div class="betlistmodal" v-if="isGiftOpen">
    <div class="betlist-content">
      <h4 class="betlist-title">쿠폰 선물하기</h4>

      <div  class="coupongift">
        <p class="giftname"><img src="@/assets/img/step1.png" />선물로 보낼 쿠폰을 확인하세요.</p>
        <p class="giftpick">선물할쿠폰<input type="text" placeholder="쿠폰테스트" /></p>
        <p class="giftname"><img src="@/assets/img/step2.png" />쿠폰받을 회원을 선택하세요.</p>
        <div class="giftable">
          <ul class="head">
            <li>선택</li>
            <li>아이디</li>
            <li>별명</li>
          </ul>
          <ul>
            <li><input type="checkbox" checked/></li>
            <li>louie32</li>
            <li>위너테스트2</li>
          </ul>
          <ul>
            <li><input type="checkbox" /></li>
            <li>louie32</li>
            <li>위너테스트2</li>
          </ul>
          <ul>
            <li><input type="checkbox" /></li>
            <li>louie32</li>
            <li>위너테스트2</li>
          </ul>
          <ul>
            <li><input type="checkbox" /></li>
            <li>louie32</li>
            <li>위너테스트2</li>
          </ul>
          <ul>
            <li><input type="checkbox" /></li>
            <li>louie32</li>
            <li>위너테스트2</li>
          </ul>
          <ul>
            <li><input type="checkbox" /></li>
            <li>louie32</li>
            <li>위너테스트2</li>
          </ul>
          <ul>
            <li><input type="checkbox" /></li>
            <li>louie32</li>
            <li>위너테스트2</li>
          </ul>
        </div>
        <p class="giftbtn"><a>쿠폰발송</a></p>

      </div>

      <div class="betclose" @click="openCouponGift"><img src="@/assets/img/wcloseicon.png" /></div>
    </div>
  </div>

</template>

<script>
import Pagination from '@/components/ui/Pagination'
export default {
  name: 'couponGift',
  components: { Pagination },
  data () {
    return {
      isGiftOpen: false
    }
  },
  methods: {
    openCouponGift () {
      this.isGiftOpen = !this.isGiftOpen
    }
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
